<template>
  <div class="dashboard-content" data-app>
    <!-- Content -->
    <div class="card">
      <div class="filters">
        <v-row>
          <v-col cols="12" lg="3" md="4" sm="6" class="mt-3">
            <v-text-field
              type="date"
              hide-details="auto"
              label="Fecha de Inicio"
              placeholder="Fecha de Inicio"
              v-model="filters.date.startAt"
            >
              <template v-slot:message> Fecha de Inicio </template></v-text-field
            >
          </v-col>
          <v-col cols="12" lg="3" md="4" sm="6" class="mt-3">
            <v-text-field
              type="date"
              hide-details="auto"
              label="Fecha de Cierre"
              placeholder="Fecha de Inicio"
              v-model="filters.date.endAt"
              ><template v-slot:label>
                <span> Fecha de Culminación </span>
              </template></v-text-field
            >
          </v-col>
        </v-row>
      </div>
      <v-data-table
        :headers="headers"
        :items="items"
        multi-sort
        hide-default-footer
        fixed-header
        loading
        loading-text="El contenido está cargando... Por favor espere"
        class="elevation-1"
      >
        <template v-slot:[`item.country`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-img
                :src="item.country.flag"
                max-height="25"
                max-width="50"
                v-bind="attrs"
                v-on="on"
              ></v-img>
            </template>
            <span> {{ item.country.name }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
  export default {
    data() {
      return {
        filters: {
          // userId: null,
          // search: '',
          // outstanding: null,
          // active: null,
          // countryId: null,
          date: { startAt: '', endAt: '' },
        },
        headers: [
          {
            text: 'Pais',
            align: 'start',
            sortable: false,
            value: 'country',
          },
          { text: 'Franquicias', value: 'franquicias', align: 'center' },
          { text: 'Consultores', value: 'consultores', align: 'center' },
          { text: 'Proveedores', value: 'proveedores', align: 'center' },
          { text: 'Asc. de Franquicias', value: 'ascFranquicias', align: 'center' },
          { text: 'Oferta Inmobiliaria', value: 'ofInmobiliaria', align: 'center' },
        ],
        items: [
          {
            country: {
              name: 'Argentina',
              flag:
                'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg',
            },
            franquicias: 200,
            proveedores: 6.0,
            consultores: 24,
            ascFranquicias: 98,
            ofInmobiliaria: 54,
          },
          {
            country: {
              name: 'Venezuela',
              flag:
                'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg',
            },
            franquicias: 200,
            proveedores: 9.0,
            consultores: 37,
            ascFranquicias: 98,
            ofInmobiliaria: 54,
          },
          {
            country: {
              name: 'México',
              flag:
                'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg',
            },
            franquicias: 300,
            proveedores: '',
            consultores: 23,
            ascFranquicias: 98,
            ofInmobiliaria: 54,
          },
          {
            country: {
              name: 'España',
              flag:
                'https://upload.wikimedia.org/wikipedia/commons/7/7b/Flag_of_Venezuela_%28state%29.svg',
            },
            franquicias: 300,
            proveedores: 3.7,
            consultores: 67,
            ascFranquicias: 98,
            ofInmobiliaria: 54,
          },
        ],
      }
    },
    /*  methods: {
       
       //query param para filtrar por intervalo de fecha

       let dateString = ''
          if (this.filters.date.startAt != '' && this.filters.date.endAt != '') {
            dateString =
              '&startAt=' +
              this.filters.date.startAt +
              '&endAt=' +
              this.filters.date.endAt
          }
    }, */
  }
</script>
<style lang="scss">
  .filters {
    padding: 10px;
  }
</style>
